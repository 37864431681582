import utility from '@/common/utility'
import auth from '@/common/auth'

export default class G19Model {
  constructor (props) {
    if (props) {
      this.resourceId = props.g19Guid
      this.g19Guid = props.g19Guid
      this.g28Guid = props.g28Guid
      this.planStatExaminationGuid = props.planStatExaminationGuid
      this.materialListGuid = props.materialListGuid
      this.currentDateCompleteNum = props.currentDateCompleteNum
      this.currentMonthCompleteNum = props.currentMonthCompleteNum
      this.totalCompleteNum = props.totalCompleteNum
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.tenderGuid = props.tenderGuid
      this.year = props.year
      this.month = props.month
      this.date = props.date
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.g19Guid) this.g19Guid = ''
    if (!this.g28Guid) this.g28Guid = ''
    if (!this.planStatExaminationGuid) this.planStatExaminationGuid = ''
    if (!this.materialListGuid) this.materialListGuid = ''
    if (!this.currentDateCompleteNum) this.currentDateCompleteNum = 0
    if (!this.currentMonthCompleteNum) this.currentMonthCompleteNum = 0
    if (!this.totalCompleteNum) this.totalCompleteNum = 0
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.year) this.year = 0
    if (!this.month) this.month = 0
    if (!this.date) this.date = 0
  }

  generatePrimaryKey () {
    this.g19Guid = utility.getUuid()
    this.resourceId = this.g19Guid
  }
}
