import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g19'

let batchAddUrl = 'batchAdd'
let defaultDataUrl = 'defaultData'
// let tenderStatUrl = 'tenderStats'
let dateTenderStatUrl = 'dateTenderStat'
let dateTenderStatSummaryUrl = 'dateTenderStatSummary'
let tenderStatUrl = 'tenderStatDev'
// let tenderStatSummaryUrl = 'tenderStatSummarys'
let tenderStatSummaryUrl = 'tenderStatSummaryDev'

class G19Service extends RestService {
  batchAdd (g19Models) {
    g19Models.forEach(g19Model => {
      g19Model.generatePrimaryKey()
    })
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), g19Models, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDefaultData (tenderGuid, planStatExaminationGuid, year, month, date) {
    return axios.get(utility.getRestFullUrl(this.resourceName, defaultDataUrl), {
      params: {
        tenderGuid,
        planStatExaminationGuid,
        year,
        month,
        date
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDateTenderStat (reportDate, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, dateTenderStatUrl), {
      params: {
        reportDate,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDateTenderStatSummary (reportDate, projectGuid, engineeringType = '土建') {
    return axios.get(utility.getRestFullUrl(this.resourceName, dateTenderStatSummaryUrl), {
      params: {
        reportDate,
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getTenderStat (reportDate, projectGuid) {
    return axios.get(utility.getRestFullUrl(this.resourceName, tenderStatUrl), {
      params: {
        reportDate,
        projectGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getTenderStatSummary (reportDate, projectGuid) {
    return axios.get(utility.getRestFullUrl(this.resourceName, tenderStatSummaryUrl), {
      params: {
        reportDate,
        projectGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g19Service = new G19Service(resourceName)

export default g19Service
