<template>
  <div ref="spreadContainer" class="spread-container">
  </div>
</template>

<script>
export default {
  name: 'SpreadJs',
  props: {
    spreadInfo: {
      type: Object,
      default: null
    },
    defaultDataList: {
      type: Array,
      default () {
        return []
      }
    },
    escapeRows: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      headerRows: [],
      dataColumns: [],
      errors: {},
      currentErrorIndex: 0
    }
  },
  computed: {
    errorKeys () {
      return Object.keys(this.errors).sort()
    }
  },
  methods: {
    initWorkbook (el) {
      // 初始化spread，目前只开一个sheet页
      this.spread = new GC.Spread.Sheets.Workbook(el, { calcOnDemand: true, sheetCount: 0 })
      // 设置右键菜单不可以使用
      this.spread.options.allowContextMenu = false
      // 设置右键菜单可用项目
      this.spread.contextMenu.menuData = this.spreadMenus
      // 设置不可粘贴excel样式
      this.spread.options.allowCopyPasteExcelStyle = false
      // 设置不可新增sheet页
      this.spread.options.newTabVisible = false

      this.spread.commandManager().setShortcutKey('clearAndEditing', GC.Spread.Commands.Key.del, false, false, false, false)

      // 初始化sheets页面
      this.initWorksheets()
    },
    initWorksheets () {
      // 添加worksheet页
      this.worksheet = new GC.Spread.Sheets.Worksheet(this.spreadInfo.sheetName)
      this.spread.addSheet(0, this.worksheet)

      // 设置剪贴板模式
      this.worksheet.options.clipBoardOptions = 1

      // 绑定样式
      this.bindStyles()

      // 初始化列头
      this.initWorksheetHeader()

      // 设置保护
      this.setProtection()

      // 绑定默认数据
      if (this.defaultDataList.length) {
        this.bindDefaultData()
      }

      // 绑定事件
      this.bindEvents()
    },
    initWorksheetHeader () {
      this.spread.suspendPaint()

      if (this.spreadInfo.columns && this.spreadInfo.columns.length) {
        this.index = 0
        this.parseColumns(null, this.spreadInfo.columns, 0)
        delete this.index

        // 计算rowSpan和colSpan
        for (let i = this.headerRows.length - 1; i > -1; --i) {
          let row = this.headerRows[i]
          row.forEach(column => {
            if (column.children && column.children.length) {
              column.colSpan = column.children.reduce((total, item) => total + item.colSpan, 0)
              column.index = column.children[0].index
            } else {
              column.colSpan = 1
            }
          })
        }
        for (let i = 0; i < this.headerRows.length; ++i) {
          let row = this.headerRows[i]
          row.forEach(column => {
            if (column.children && column.children.length) {
              column.rowSpan = 1
            } else {
              column.rowSpan = this.headerRows.length - i
            }
          })
        }

        // 设置头部行数
        this.worksheet.setRowCount(this.headerRows.length, GC.Spread.Sheets.SheetArea.colHeader)
        // 设置列数
        this.worksheet.setColumnCount(this.dataColumns.length, GC.Spread.Sheets.SheetArea.colHeader)
        // 设置头部具体信息
        for (let i = 0; i < this.headerRows.length; ++i) {
          let row = this.headerRows[i]
          row.forEach(column => {
            this.worksheet.addSpan(i, column.index, column.rowSpan, column.colSpan, GC.Spread.Sheets.SheetArea.colHeader)
            this.worksheet.setValue(i, column.index, column.label, GC.Spread.Sheets.SheetArea.colHeader)
            this.worksheet.setColumnWidth(column.index, column.width)
          })
        }
      }

      this.spread.resumePaint()
    },
    parseColumns (parent, columns, level) {
      if (!this.headerRows[level]) {
        this.headerRows[level] = []
      }
      this.headerRows[level] = this.headerRows[level].concat(columns)
      columns.forEach(column => {
        column.parent = parent
        if (column.children && column.children.length) {
          this.parseColumns(column, column.children, level + 1)
        } else {
          column.index = this.index++
          this.dataColumns.push(column)
        }
      })
    },
    setProtection () {
      this.worksheet.options.isProtected = true

      this.dataColumns.forEach(column => {
        if (column.editable) {
          this.worksheet.setStyleName(-1, column.index, 'unLockStyle')
        }
      })
    },
    bindDefaultData () {
      let dataArray = []
      this.defaultDataList.forEach(item => {
        let dataItem = []
        this.dataColumns.forEach(column => {
          dataItem.push(item[column.dataKey] ? item[column.dataKey] : '')
        })
        dataArray.push(dataItem)
      })
      this.worksheet.setArray(0, 0, dataArray)
      this.worksheet.setRowCount(this.defaultDataList.length)
    },
    bindEvents () {
      // 绑定valueChanged事件
      this.worksheet.bind(GC.Spread.Sheets.Events.ValueChanged, (sender, info) => {
        // if (isNaN(info.oldValue) && isNaN(info.newValue)) {
        //   this.setValue(info.row, info.col, '')
        // }
        this.rowCompute(info.row)
      })

      // 绑定拖拽事件
      this.worksheet.bind(GC.Spread.Sheets.Events.DragFillBlockCompleted, (e, info) => {
        for (let i = 0; i < info.fillRange.rowCount; ++i) {
          this.rowCompute(info.fillRange.row + i)
        }
      })

      // 绑定粘贴事件
      this.worksheet.bind(GC.Spread.Sheets.Events.ClipboardPasted, (sender, info) => {
        for (let i = 0; i < info.cellRange.rowCount; ++i) {
          this.rowCompute(i + info.cellRange.row)
        }
      })
    },
    rowCompute (rowIndex) {
      if (this.escapeRows.indexOf(rowIndex) !== -1) {
        return
      }
      this.dataColumns.filter(column => column.formula).forEach((column, index) => {
        let row = this.getRow(rowIndex)
        this.validateRow(rowIndex)
        let num = column.formula(row, this.defaultDataList)
        this.worksheet.setValue(rowIndex, column.index, num)
      })
    },
    getRow (rowIndex) {
      let row = {
        index: rowIndex
      }
      this.dataColumns.forEach(column => {
        row[column.dataKey] = this.worksheet.getValue(rowIndex, column.index)
      })
      return row
    },
    validate () {
      for (let i = 0; i < this.defaultDataList.length; ++i) {
        this.validateRow(i)
      }
    },
    validateRow (rowIndex) {
      if (this.escapeRows.indexOf(rowIndex) !== -1) {
        return
      }
      let result = true
      this.dataColumns.filter(column => column.validator).forEach(column => {
        let row = this.getRow(rowIndex)
        if (!column.validator(row)) {
          this.setError(row.index, column.index)
          result = false
        } else {
          this.clearError(row.index, column.index)
        }
      })
      return result
    },
    setError (rowIndex, colIndex) {
      let errorKey = `${rowIndex}_${colIndex}`
      this.$set(this.errors, errorKey, {
        rowIndex,
        colIndex
      })
      setTimeout(() => {
        this.worksheet.setStyleName(rowIndex, colIndex, 'invalidStyle')
      }, 2)
    },
    clearError (rowIndex, colIndex) {
      let errorKey = `${rowIndex}_${colIndex}`
      this.$delete(this.errors, errorKey)
      setTimeout(() => {
        this.worksheet.setStyleName(rowIndex, colIndex, '')
      }, 2)
    },
    bindStyles () {
      let invalidStyle = new GC.Spread.Sheets.Style()
      invalidStyle.name = 'invalidStyle'
      invalidStyle.borderLeft = new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.thin)
      invalidStyle.borderRight = new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.thin)
      invalidStyle.borderTop = new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.thin)
      invalidStyle.borderBottom = new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.thin)
      invalidStyle.backColor = 'red'
      invalidStyle.foreColor = 'white'

      let unLockStyle = new GC.Spread.Sheets.Style()
      unLockStyle.name = 'unLockStyle'
      unLockStyle.borderLeft = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      unLockStyle.borderRight = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      unLockStyle.borderTop = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      unLockStyle.borderBottom = new GC.Spread.Sheets.LineBorder('gray', GC.Spread.Sheets.LineStyle.thin)
      unLockStyle.backColor = 'green'
      unLockStyle.foreColor = 'white'
      unLockStyle.locked = false

      this.spread.addNamedStyle(invalidStyle)
      this.spread.addNamedStyle(unLockStyle)
    },
    getData () {
      let resultData = []
      for (let i = 0; i < this.defaultDataList.length; ++i) {
        let row = this.getRow(i)
        resultData.push(row)
      }
      return resultData
    },
    getUsefullData () {
      let resultData = []
      Object.keys(this.worksheet.toJSON().data.dataTable).forEach(key => {
        let i = parseInt(key)
        let row = this.getRow(i)
        resultData.push(row)
      })
      return resultData
    }
  },
  mounted () {
    this.initWorkbook(this.$refs.spreadContainer)
  }
}
</script>

<style scoped lang="scss">
.spread-container {
  width: 100%;
  height: 100%;
}
</style>
